<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">스크립트조회</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
         <div>
          <div class="form-inp sm">
            <v-select
              v-model="aspCustKey"
              :items="aspCustList"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택하세요"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="SEARCH_USE_NEWTY"
              :items="SEARCH_USE_TY"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택하세요"
              label="사용구분"
            >
              <template v-slot:label>
                사용구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                @change="startDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
                :min="dates[0]"
                @change="endDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="CNSL_DIV"
              :items="dropcnslDivItems"
              item-text="title"
              outlined
              hide-details
              placeholder="선택하세요"
              label="분류구분"
            >
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="SEARCH_REQ_NEWTY"
              :items="SEARCH_REQ_TY"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택하세요"
              label="변경요청여부"
            >
            </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-text-field
              class="form-inp full"
              name="searchCont"
              label="스크립트명"
              outlined
              hide-details
              v-model="searchCont"
            ></v-text-field>
          </div>
         </div>
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchScriptList"> 조회 </v-btn>
          </div>
       </div>
    </div>
    <!-- 스크립트 목록 -->
    <div class="d-flex">
      <div class="box-wrap col-6">
        <h2 class="tit-h2 d-flex">스크립트 목록</h2>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="gridDataHeaders"
            :items="gridDataText"
            item-key="ROW_NUMBER"
            hide-default-footer
            class="grid-default"
            height="150"
            single-select
            @click:row="getScriptDetail"
            fixed-header
            :item-class="isActiveRowScp"
          >
          </v-data-table>
        </div>
        <div class="mt-5">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnUnfold')" outlined class="btn-point" @click="unfoldTreeview">전체펼치기</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFold')" outlined class="btn-default ml-2" @click="foldTreeview">전체접기</v-btn>
        </div>
        <div class="mt-3">
          <div class="tree-wrap mt-2 scrollable" style="height: 397px">
            <template>
              <v-treeview
                :items="treeItems"
                item-disabled="locked"
                activatable
                open-on-click
                item-key="id"
                ref="treeview1"
              >
              <template v-slot:prepend="{ open, item }" >
                <v-icon color="#8B98DD" v-if="item.children">
                  {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
              </template>

              <template v-slot:label="{ item }">
                <a @click="getItemDetail(item)">{{ item.name }}</a>
                <v-icon color="#F06969" >
                  {{ item.locked ? 'mdi-close-circle-outline' : '' }}
                </v-icon>
              </template>
              </v-treeview>
            </template>
          </div>
        </div>
      </div>
      <!-- 상담 스크립트 상세 -->
      <div class="box-wrap ml-4 flex-grow-1" >
        <h2 class="tit-h2 d-flex">상담 스크립트상세
          <div class="ml-auto align-self-center">

          </div>
        </h2>
        <div class="box-ct">
          <div class="table-form">
            <table>
              <caption class="hide">
                항목
              </caption>
              <colgroup>
                <col width="127px" />
                <col width="" />
                <col width="127px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">상위스크립트명</th>
                  <td colspan="3">
                    <v-text-field
                      class="form-inp full"
                      v-model="upperScriptNm"
                      name="upperScriptNm"
                      outlined
                      hide-details
                      disabled
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">스크립트명</v-badge>
                  </th>
                  <td colspan="3">
                    <v-text-field
                      class="form-inp full"
                      v-model="thisScriptNm"
                      name="thisScriptNm"
                      label=""
                      outlined
                      hide-details
                      disabled
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용구분</v-badge>
                  </th>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      v-model="USE_NEWTY"
                      hide-details
                      outlined
                      disabled
                    ></v-text-field>
                  </td>
                  <th scope="row">
                    스크립트기간
                  </th>
                  <td>
                    <v-text-field
                      v-model="detailDateRange"
                      disabled
                      outlined
                      hide-details
                      class="form-inp full icon-calendar"
                      append-icon="svg-calendar"
                      label=""

                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용여부</v-badge>
                  </th>
                  <td class="">
                    <div class="form-inp full">
                      <v-select
                        v-model="selectedDrop"
                        :items="useYnDrop"
                        item-text="text"
                        item-value="value"
                        outlined
                        placeholder="선택하세요"
                        hide-details
                        return-object
                        disabled
                      ></v-select>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">정렬순서</v-badge>
                  </th>
                  <td class="">
                    <v-text-field
                      class="form-inp full"
                      type="number"
                      v-model="ordSeq"
                      name="ordSeq"
                      value="0"
                      hide-details
                      outlined
                      disabled
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">스크립트내용</v-badge>
                  </th>
                  <td colspan="3">
                   <v-textarea
                    class="textarea-type0"
                    v-model="scriptDesc"
                    label=""
                    no-resize
                    outlined
                    disabled
                  ></v-textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h2 class="tit-h2 d-flex mt-3">파일목록
          <div class="ml-auto align-self-center">

          </div>
        </h2>
        <div class="box-ct">
          <v-data-table
            dense
            :headers="gridFileHeaders"
            :items="gridFileText"
            item-key="FILE_KEY"
            hide-default-footer
            class="grid-default"
            height="271px"
            no-data-text="검색된 결과가 없습니다."
            @click:row="setGirdFileSelected"
            :item-class="isActiveRow"
          >
            <template v-slot:item.FILE_SIZE = {item}>
              {{mixin_getFileSize(item.FILE_SIZE)}}
            </template>
            <template v-slot:item.DOWNLOAD = {item}>
              <div>
                <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="small btn-default" @click="mixin_fileDownload(item, 'phone', 'images')">다운</v-btn>
              </div>
          </template>
          </v-data-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";

export default {
  mixins: [mixin],
  name: "MENU_M110601", //name은 'MENU_' + 파일명 조합

  data() {
    return {
      menu1: false,
      menu2: false,
      aspCustKey: "",
      selectedDrop: [],
      CNSL_DIV: "",
      USE_SRCH_YN: "",
      useYnDrop: [
        {text:"Y", value:"Y"},{text:"N", value:"N"}
      ],
      dropcnslDivItems: [
        {title: "전체", value: ""},
        {title: "대분류", value: "1"},
        {title: "중분류", value: "2"},
        {title: "소분류", value: "3"},
      ],

      alertMsg: {
        comChk: "회사구분란에서 회사를 선택 후 이용해주십시오.",
      },

      alertIcon: {
        noti: "svg-done-lg",
        err: "svg-error-lg",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },

      treeItems: [],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      detailDates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        { text: "No", align: "center", value: "ROW_NUMBER", width: "70px", sortable: true, },
        { text: "대분류명", value: "SCRIPT_NM_1", align: "left", sortable: true },
        { text: "중분류명", value: "SCRIPT_NM_2", align: "left", sortable: true, },
        { text: "소분류명", value: "SCRIPT_NM_3", align: "left", sortable: true, },
        { text: "스크립트명", value: "SCRIPT_TIT", align: "left", sortable: true },
        { text: "분류", value: "LEVEL_NO_NM", align: "center", sortable: true },
        { text: "사용구분", value: "USE_TY", align: "center", sortable: true},
        { text: "사용여부", value: "USE_YN", align: "center", sortable: true },
        { text: "변경요청", value: "REQ_YN", align: "center", sortable: true },
      ],
      gridDataText: [],
      gridFileHeaders: [
        { text : "파일명", value: "ORIGINAL_FILENAME", align:"left", sortable:true,},
        { text : "파일사이즈", value: "FILE_SIZE", align:"center", width: "100px", sortable:true,},
        { text : "다운로드", value: "DOWNLOAD", align:"center", width: "100px", sortable:true,},
        { text : "다운로드수", value: "DNLOD_CNT", align:"center", width:"100px", sortable:true,},
      ],
      gridFileText: [],
      gridFileSelected : {},
      scriptDesc: "",
      upperScriptNm: "",
      FILE_GROUP_KEY: "",
      ordSeq: 0,
      detailDateRange: "",
      clickedData: [],
      newScriptAdd: false,
      thisScriptNm: "",
      userYNScriptNm: "",
      ordSeqScriptNm: "",

      SEARCH_USE_TY:[],
      SEARCH_USE_NEWTY:"",

      SEARCH_REQ_TY:[
        {CD_NM:"전체", CD: ""},
        {CD_NM:"Y", CD: "reqY"},
        {CD_NM:"N", CD: "reqN"}
      ],
      SEARCH_REQ_NEWTY:"",

      USE_TY:[],
      USE_NEWTY:'',

      SCP_ID:'',

      searchCont:'',
    };
  },
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    startDetailDate(e) {
      this.detailDates[0] = e;
    },
   /* endDetailDate(e) {
      this.detailDates[1] = e;
    },*/
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    endDetailDate(e) {
      this.detailDates[1] = e;
    },
    unfoldTreeview(){
      this.$refs.treeview1.updateAll(true);
    },
    foldTreeview(){
      this.$refs.treeview1.updateAll(false);
    },

    // 스크립트 목록에서 클릭 시 상담 스크립트 상세 스크립트 정보 출력
    async getScriptDetail(item, row){
      this.SCP_ID = item.SCRIPT_ID;

      let detailUrl = "/api/phone/script/inqire/detail";
      let fileUrl = "";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = detailUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey;
      requestData.sendData["SCRIPT_ID"] = item.SCRIPT_ID;

      let response = await this.common_postCall(requestData);
      await this.setScriptDetail(response);
      this.gridFileText = await this.mixin_getFileList(this.FILE_GROUP_KEY);
    },

    isActiveRowScp(item){
      const activeClass = item.SCRIPT_ID === this.SCP_ID ? 'active' : '';
      return activeClass;
    },

    async setScriptDetail(response){
      let data = response.DATA;
      console.log("data", data);

      if( data.length == 1 ){
        // 신규를 누르거나 했을때 부모로 가져오기 위해서 데이터를 가지고 있음
        this.clickedData = data;

        this.upperScriptNm = data[0].UPPER_SCRIPT_NM; // 상위스크립트명
        this.thisScriptNm = data[0].SCRIPT_TIT;       // 스크립트명
        this.userYNScriptNm = data[0].USE_YN;         // 사용 여부
        this.ordSeqScriptNm = data[0].ORD_SEQ;        // 정렬 순서

        // 시작 ~ 종료 날짜
        this.detailDates[0] = data[0].BEGIN_DATE.substring(0, 4) + "-" + data[0].BEGIN_DATE.substring(4, 6) + "-" + data[0].BEGIN_DATE.substring(6, 8);
        this.detailDates[1] = data[0].EOT_DATE.substring(0, 4) + "-" + data[0].EOT_DATE.substring(4, 6) + "-" + data[0].EOT_DATE.substring(6, 8);
        this.detailDateRange = this.detailDates[0] + " ~ " + this.detailDates[1];

        this.USE_NEWTY = data[0].USE_TY;                 // 사용구분
        this.ordSeq = data[0].ORD_SEQ;                // 정렬순서
        this.scriptDesc = data[0].SCRIPT_RMK;         // 스크립트내용
        this.FILE_GROUP_KEY = data[0].FILE_GROUP_KEY;
        this.setSelected(data[0].USE_YN);
        //console.log(this.useYnDrop[0]);        // 사용여부
      }
    },

    async searchScriptList(){
      // 회사구분이 비었는지? 비었다면 알람창 띄워주기 회사구분은 필수 입력
      if (this.aspCustKey == null || this.aspCustKey == "") {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: this.alertMsg.comChk,
          iconClass: this.alertIcon.err,
          type: this.alertType.default,
        });
        return false;
      }
      
      if (this.SEARCH_USE_NEWTY == "" || this.SEARCH_USE_NEWTY == undefined) {
        this.showAlert("사용구분을 선택해주세요.");
        return;
      }

      // 스크립트 목록 조회
      let scriptListUrl = "/api/phone/script/inqire/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = scriptListUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];           //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];               //접수종료일자
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;                     //업무구분
      requestData.sendData["SEARCH_CONT"] = this.searchCont;                   //처리구분
      requestData.sendData["USE_TY"] = this.SEARCH_USE_NEWTY;                   //사용구분
      requestData.sendData["REQ_TY"] = this.SEARCH_REQ_NEWTY;                   //변경요청현황

      let response = await this.common_postCall(requestData);
      this.setScriptList(response);
      this.getTreeviewList();
      this.clickedData = [];
      this.upperScriptNm = "";
      let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      this.detailDateRange = today + " ~ " + today;
      this.thisScriptNm = "";
      this.ordSeq = 1;
      this.userYNScriptNm = "";
      this.ordSeqScriptNm = "";
      this.scriptDesc = "";
      this.clickedData = [];
      this.newScriptAdd = false;
      //console.log(response);
    },
    // 스크립트 목록 set해주기
    setScriptList(response){
      let header = response.HEADER;
      let data = response.DATA;
        console.log("data  ", data);

      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){
        this.gridDataText = data;
        for(var i = 0; i < data.length; i++){
          if (this.gridDataText[i].CNT == '0') {
            this.gridDataText[i]['REQ_YN'] = 'N' 
          } else {
            this.gridDataText[i]['REQ_YN'] = 'Y'
          }
          let element = {
            id: data[i].C_NODE_NO,
            pid: data[i].P_NODE_NO,
            name: data[i].SCRIPT_TIT,
            nodeLvl: data[i].NODE_LVL,
            children:[],
          };
        }
        //console.log(data);
      } else {
        this.gridDataText = [];
      }
    },
    async getTreeviewList(){
      // 스크립트 트리뷰 조회
      let scriptListUrl = "/api/phone/script/inqire/tr/inqire";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = scriptListUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.tr.inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];        //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];          //접수종료일자
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;           //업무구분
      requestData.sendData["SEARCH_CONT"] = this.searchCont;     //처리구분
      requestData.sendData["USE_TY"] = this.SEARCH_USE_NEWTY;    //사용구분
      requestData.sendData["REQ_TY"] = this.SEARCH_REQ_NEWTY;    //변경요청현황

      let response = await this.common_postCall(requestData);
      await this.setTreeviewList(response);
      this.unfoldTreeview();
    },
    async setTreeviewList(response){ // 트리뷰를 항목에 맞게 set해준다.
      let header = response.HEADER;
      let data = response.DATA;
      /*
        {
          id: 15,
          name: 'menu3 :',
          children: [
            { id: 16, name: 'menu3-1' },
            { id: 17, name: 'menu3-2' },
            { id: 18, name: 'menu3-3' },
          ],
        },
      */
      let items = [];
      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){

        if(this.CNSL_DIV == ""){ // 분류구분을 전체로 했을때 모든 트리뷰가 나오게

          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };

            if( element.nodeLvl == 2 ){
              for(var j = 0; j < items.length; j++){
                if( element.pid == items[j].id ){
                  //console.log(`push complate ${items[j].id}`);
                  items[j].children.push(element);
                }
              }
            } else if( element.nodeLvl == 3 ){
              for(var k = 0; k < items.length; k++){
                for(var l = 0; l < items[k].children.length; l++){
                  if( element.pid == items[k].children[l].id ){
                    //console.log(`push complate ${items[k].children[l].id}`);
                    items[k].children[l].children.push(element);
                  }
                }
              }
            } else {
              items.push(element);
            }
          }
        } else if(this.CNSL_DIV == 1){ // 분류 구분이 대분류라면 트리뷰에서 대분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        } else if(this.CNSL_DIV == 2){ // 분류 구분이 중분류라면 트리뷰에서 중분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        } else if(this.CNSL_DIV == 3){ // 분류 구분이 소분류라면 트리뷰에서 소분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        }

        this.treeItems = items;
        //console.log(this.treeItems);
      } else {
        this.treeItems = [];
      }

    },
    // 트리뷰에서 각 폴더, 파일모양을 클릭했을 때
    async getItemDetail(item){
      // 스크립트 트리뷰 조회
      let detailUrl = "/api/phone/script/inqire/detail";
      let fileUrl = "";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = detailUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.detail";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["SCRIPT_ID"] = item.id;     //처리구분

      let response = await this.common_postCall(requestData);
      //console.log(response);
      await this.setItemDetail(response);

      this.gridFileText = await this.mixin_getFileList(this.FILE_GROUP_KEY);

    },
    async setItemDetail(response){
      let data = response.DATA;
      /*
        ASP_CUST_KEY: "001"
        ASP_NEWCUST_KEY: "001"
        BEGIN_DATE: "20200917"
        EOT_DATE: "29991231"
        FILE_GROUP_KEY: ""
        LVL_NO: "1"
        ORD_SEQ: "1"
        SCRIPT_ID: "0002"
        SCRIPT_RMK: ""
        SCRIPT_TIT: "업무지원 스크립트"
        UPPER_SCRIPT_ID: ""
        UPPER_SCRIPT_NM: ""
        USE_YN: "Y"
      */
     /*
        scriptDesc: "",
        upperScriptNm: "",
        thisScriptNm: "",
        ordSeq: 0,
     */
      //console.log(data);
      if( data.length == 1 ){
        // 신규를 누르거나 했을때 부모로 가져오기 위해서 데이터를 가지고 있음
        this.clickedData = data;

        this.upperScriptNm = data[0].UPPER_SCRIPT_NM; // 상위스크립트명
        this.thisScriptNm = data[0].SCRIPT_TIT;       // 스크립트명
        this.userYNScriptNm = data[0].USE_YN;         // 사용 여부
        this.ordSeqScriptNm = data[0].ORD_SEQ;        // 정렬 순서

        // 시작 ~ 종료 날짜
        this.detailDates[0] = data[0].BEGIN_DATE.substring(0, 4) + "-" + data[0].BEGIN_DATE.substring(4, 6) + "-" + data[0].BEGIN_DATE.substring(6, 8);
        this.detailDates[1] = data[0].EOT_DATE.substring(0, 4) + "-" + data[0].EOT_DATE.substring(4, 6) + "-" + data[0].EOT_DATE.substring(6, 8);
        this.detailDateRange = this.detailDates[0] + " ~ " + this.detailDates[1];

        this.USE_NEWTY = data[0].USE_TY;                 // 사용구분
        this.ordSeq = data[0].ORD_SEQ;                // 정렬순서
        this.scriptDesc = data[0].SCRIPT_RMK;         // 스크립트내용
        this.FILE_GROUP_KEY = data[0].FILE_GROUP_KEY; // 파일그룹키
        this.setSelected(data[0].USE_YN);
        //console.log(this.useYnDrop[0]);        // 사용여부

      }
    },
    setSelected(val){
      this.selectedDrop = { text: val, value: val };
    },

    // 트리뷰 클릭시 list에는 하위 리스트들이 나오게
    async gettreeViewClickList(data){

      // 트리뷰 클릭했을때 해당 분류 하위부분이 목록에 list가 되게끔
      let scriptListUrl = "/api/phone/script/inqire/low/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = scriptListUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.low";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];        //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];          //접수종료일자
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;           // 분류 구분 ->  트리뷰에서 대 / 중 / 소에 따라서 바뀌어야 한다.
      requestData.sendData["USE_SRCH_YN"] = this.USE_SRCH_YN;     //처리구분
      requestData.sendData["SCRIPT_ID"] = data[0].SCRIPT_ID;     // 현재 분류 id
      requestData.sendData["LVL_NO"] = data[0].LVL_NO;     //현재 분류 구분 번호


      let response = await this.common_postCall(requestData);
      this.settreeViewClickList(response);
    },

    settreeViewClickList(response){
      let header = response.HEADER;
      let data = response.DATA;

      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){
        this.gridDataText = data;
        for(var i = 0; i < data.length; i++){
          let element = {
            id: data[i].C_NODE_NO,
            pid: data[i].P_NODE_NO,
            name: data[i].SCRIPT_TIT,
            nodeLvl: data[i].NODE_LVL,
            children:[],
          };
        }
        //console.log(data);
      } else {
        this.gridDataText = [];
      }
    },


    changeSelected(e){
      //console.log(e);
      this.selectedDrop = {text: e.text, value: e.value};
    },

    clearScreen(){
      this.searchScriptList();
      this.getTreeviewList();
      this.upperScriptNm = "";
      let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      this.detailDateRange = today + " ~ " + today;
      this.thisScriptNm = "";
      this.ordSeq = 1;
      this.scriptDesc = "";
      this.clickedData = [];
      this.newScriptAdd = false;
      this.FILE_GROUP_KEY = "";
      this.gridFileText = [];
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    
    setGirdFileSelected(item){
      this.gridFileSelected = item;
    },
    isActiveRow(item){
      return item.FILE_KEY == this.gridFileSelected.FILE_KEY? 'active':'';
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    detailDateRangeText() {
      var newStartDate = this.detailDates[0];
      var newEndDate = this.detailDates[1];
      this.detailDateRange = newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  async mounted() {
    // this.searchScriptList();
    // this.getTreeviewList();
    let codeName = ["SCP001"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.SEARCH_USE_TY = [...this.SEARCH_USE_TY, ...common_code[0].group_value];
    this.USE_TY = common_code[0].group_value;
  },
};
</script>

<style>

</style>
